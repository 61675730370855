
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import dialog from "@/vuex/dialog";
  import mixinUpdateStdincome from "@/mixins/mixinUpdateStdincome";
  import * as util from "@/util";
  import UtilPopup from "@/components/util/Popup.vue";
  import CommonYearmonth from "@/components/common/Yearmonth.vue";

  @Component({
    components: {
      UtilPopup,
      CommonYearmonth,
    },
  })
  export default class Dialog extends Mixins(mixinUpdateStdincome) {
    dialog_index = "update_stdincome_create";

    created() {
      this.m.closeDialog({ name: this.dialog_index });
      let dateobj = new Date();
      if (util.obj2day(dateobj) >= 15) {
        dateobj = util.prev_month(dateobj);
        dateobj = util.prev_month(dateobj);
        var yearmonthstr = util.formatDate(dateobj, "yyyy-mm");
      } else {
        dateobj = util.prev_month(dateobj);
        dateobj = util.prev_month(dateobj);
        dateobj = util.prev_month(dateobj);
        var yearmonthstr = util.formatDate(dateobj, "yyyy-mm");
      }
      this.m.setTmpObjects({
        name: "update_stdincome",
        val: {
          started_yearmonth: yearmonthstr,
        },
      });
    }

    create_update_stdincome() {
      this.m.create({
        name: "update_stdincome",
        args: { update_stdincome: this.m.tmp_objects["update_stdincome"] },
      });
    }

    get ended_yearmonth() {
      if (this.tmp_update_stdincome.started_yearmonth == null) {
        return "開始年月を入力";
      } else {
        let yearmonth = util.date2obj(`${this.tmp_update_stdincome.started_yearmonth}-01`);
        yearmonth = util.next_month(yearmonth);
        yearmonth = util.next_month(yearmonth);
        return util.formatDate(yearmonth, "yyyy年mm月");
      }
    }

    get tmp_update_stdincome() {
      return this.m.tmp_objects["update_stdincome"];
    }
    set tmp_update_stdincome(val) {
      this.m.setTmpObjects({ name: "update_stdincome", val });
    }

    get is_display() {
      if (this.m.dialog_flag[this.dialog_index]) {
        return true;
      } else {
        return false;
      }
    }
    set is_display(val: boolean) {
      if (val) {
        this.m.openDialog({ name: this.dialog_index });
      } else {
        this.m.closeDialog({ name: this.dialog_index });
      }
    }
  }
