
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinUpdateStdincome from '@/mixins/mixinUpdateStdincome';
import global from "@/vuex/update_stdincome/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/update_stdincome/manager";
import UpdatestdincomeTableControl from "@/components/updatestdincome/table/Control.vue";
import UpdatestdincomeTableTable from "@/components/updatestdincome/table/Table.vue";
import UpdatestdincomePagerFoot from "@/components/updatestdincome/pager/Foot.vue";
import UpdatestdincomeDialogCsv from "@/components/updatestdincome/dialog/Csv.vue";

@Component({
  components: {
    UpdatestdincomeTableControl,
    UpdatestdincomeTableTable,
    UpdatestdincomePagerFoot,
    UpdatestdincomeDialogCsv,
  }
})
export default class Main extends Mixins(mixinUpdateStdincome) {
}
