
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import mixinUpdateStdincome from '@/mixins/mixinUpdateStdincome';
@Component({
  components: {
  }
})
export default class Foot extends Mixins(mixinUpdateStdincome) {
  
}
