import { Modal } from "ant-design-vue";
import { ref } from "vue";
class InfoDialog {
  modal: any = ref(false);

  public openDialog(content) {
    Modal.destroyAll();
    this.modal.value = Modal.info({
      title: (h) =>
        h(
          "span",
          {
            style: {
              display: "inline-block",
              width: "100%",
              color: "#fff",
              "line-height": "40px",
              "font-size": "20px",
              "margin-bottom": "10px",
              "text-align": "center",
              "font-weight": "600",
              "margin-top": "5px",
              "background-color": "#9b9a9b",
            },
          },
          content.title,
        ),
      icon: (h) => h("span", ""),
      content: (h) => {
        return h("div", [
          content.msg
            ? h("p", {
                style: {
                  "line-height": "35px",
                  "font-size": "16px",
                  "text-align": "left",
                  "font-weight": "500",
                  padding: "10px 10px 0",
                },
                domProps: { innerHTML: content.msg },
              })
            : null,
          content.detail
            ? h("p", {
                style: { "line-height": "25px", "font-size": "14px", "text-align": "center" },
                domProps: { innerHTML: content.detail },
              })
            : null,
          content.img
            ? h("img", {
                style: { "margin-top": "10px", width: "100%" },
                domProps: { src: "/img/" + content.img },
              })
            : null,
        ]);
      },
      width: content.max_width ? content.max_width.replace("px", "") : 750,
    });
  }
}

const info_dialog = new InfoDialog();
export default info_dialog;
