
import { Component, Vue, Emit, Prop, Watch, Mixins } from 'vue-property-decorator';

@Component({
  components: {
  }
})
export default class Pager extends Vue {
  @Prop({default: true}) prev_cond: boolean;
  @Prop({default: true}) next_cond: boolean;
  @Prop({default: 'other'}) theme: string;
  @Prop({default: true}) pager: boolean;

  @Emit()
  click_prev() {
    return true;
  }
  @Emit()
  click_next() {
    return true;
  }
}
