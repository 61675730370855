import { Component, Vue, Mixins } from "vue-property-decorator";
import utilMixins from "@/mixins";
import {
  makeTableMixin,
  dataDescript,
  validateDescript,
  latestDescript,
} from "@/mixins/Descriptor";
import vuex_data from "@/vuex/vuex_data";
import vuex_manager from "@/vuex/vuex_manager";

const tableDescriptor = {
  manager: vuex_manager.update_stdincome_manager,
  data: {
    source: "yearly",
    key: "update_stdincome_masters",
    sort_key: [
      { key: "status", keys: ["data", "is_valid"] },
      { key: "code", keys: ["yearly", "employee_hash", ["data", "employee_id"], "code"] },
      {
        key: "social_ins_id",
        keys: ["yearly", "employee_hash", ["data", "employee_id"], "social_ins_id"],
      },
      { key: "name", keys: ["yearly", "employee_hash", ["data", "employee_id"], "kana"] },
      {
        key: "hiring_type",
        keys: ["yearly", "employee_hash", ["data", "employee_id"], "hiring_type"],
      },
      { key: "branch_id", keys: ["yearly", "employee_hash", ["data", "employee_id"], "branch_id"] },
      { key: "is_reflected", keys: ["data", "is_reflected"] },
    ],
    default_sort_key: [
      { key: "is_valid", keys: ["data", "is_valid"], sort_mode: "desc" },
      {
        key: "is_candidate",
        keys: ["func", "is_candidate", [["data", "employee_id"]]],
        sort_mode: "asc",
      },
      {
        key: "social_ins_id",
        keys: ["yearly", "employee_hash", ["data", "employee_id"], "social_ins_id"],
        sort_mode: "asc",
      },
    ],
    search_targets: [
      { type: "normal", keys: ["yearly", "employee_hash", ["data", "employee_id"], "code"] },
      { type: "normal", keys: ["yearly", "employee_hash", ["data", "employee_id"], "name"] },
      { type: "kana", keys: ["yearly", "employee_hash", ["data", "employee_id"], "kana"] },
    ],
  },
};

const globalDescriptor = {
  name: "global",
  vuex: vuex_data.update_stdincome_global,
  indexers: ["update_stdincome_id"],
  fetch(self) {
    if (self.param_update_stdincome_id == 0) return {};
    else return { update_stdincome_id: self.update_stdincome_id };
  },
  dependencies: [],
};
const yearlyDescriptor = {
  name: "yearly",
  vuex: vuex_data.update_stdincome_yearly,
  indexers: ["update_stdincome_id", "branch_id"], //ここのindexは、this.で参照できるようなものでなくてはならない branch_idだとthis.で参照できないからviewing_branchにした
  fetch(self) {
    return { update_stdincome_id: self.update_stdincome_id, branch_id: self.branch_id };
  },
  dependencies: ["global"],
};
const candidateDescriptor = {
  name: "candidatae",
  vuex: vuex_data.update_stdincome_candidate,
  indexers: ["payment_info_id", "branch_id"], //ここのindexは、this.で参照できるようなものでなくてはならない branch_idだとthis.で参照できないからviewing_branchにした
  fetch(self) {
    return { payment_info_id: self.payment_info_id, branch_id: self.branch_id };
  },
  dependencies: ["global", "yearly"],
};

@Component
export default class mixinStdincome extends Mixins(makeTableMixin(tableDescriptor), utilMixins) {
  get global() {
    return vuex_data.update_stdincome_global.get(this);
  }
  get yearly() {
    return vuex_data.update_stdincome_yearly.get(this);
  }
  get candidate() {
    return vuex_data.update_stdincome_candidate.get(this);
  }
  get global_latest() {
    return latestDescript(globalDescriptor);
  }

  get update_stdincome_id() {
    if (this.param_update_stdincome_id == 0) {
      this.param_update_stdincome_id = this.global_latest.latest_id;
      return this.global_latest.latest_id;
    } else {
      return this.param_update_stdincome_id;
    }
  }
  set update_stdincome_id(val) {
    this.param_update_stdincome_id = val;
  }
  get param_update_stdincome_id() {
    var update_stdincome_id = this.query.update_stdincome_id;
    if (update_stdincome_id === undefined) {
      update_stdincome_id = 0;
      this.query = { update_stdincome_id };
    }
    return update_stdincome_id;
  }
  set param_update_stdincome_id(val) {
    this.page = 1;
    this.query_push = { update_stdincome_id: val };
  }

  get branch_id() {
    var branch_id = this.query.viewing_branch_update_stdincome;
    if (branch_id === undefined) {
      branch_id = 0;
      this.query = { viewing_branch_update_stdincome: branch_id };
    }
    return branch_id;
  }
  set branch_id(val) {
    this.page = 1;
    this.query = { viewing_branch_update_stdincome: val };
  }

  get payment_info_id() {
    if (this.yearly && this.yearly.payment_info) {
      return this.yearly.payment_info.id;
    } else {
      return null;
    }
  }

  get editable_update_stdincome() {
    return true;
  }
  get reflectable_update_stdincome() {
    return true;
  }

  is_candidate(employee_id) {
    return this.candidate &&
      this.candidate.stdincome_list &&
      this.candidate.stdincome_list.indexOf(employee_id) !== -1
      ? -1
      : 1;
  }
}
