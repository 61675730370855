
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinUpdateStdincome from '@/mixins/mixinUpdateStdincome';
import global from "@/vuex/update_stdincome/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/update_stdincome/manager";
import CommonToggle from "@/components/common/Toggle.vue";
import CommonButton from "@/components/common/Button.vue";

@Component({
  components: {
    CommonToggle,
    CommonButton,
  }
})
export default class Row extends Mixins(mixinUpdateStdincome) {
  @Prop() update_stdincome_master;

  get yearmonthes() {
    var yearmonthes = [];
    var dateobj = util.date2obj(this.global.update_stdincome.started_yearmonth+'-01');
    yearmonthes.push(util.formatDate(dateobj, 'yyyy-mm'));
    yearmonthes.push(util.formatDate(util.next_month(dateobj), 'yyyy-mm'));
    yearmonthes.push(util.formatDate(util.next_month(dateobj), 'yyyy-mm'));
    return yearmonthes;
  }

  open_error() {
    if (this.update_stdincome_master.is_error) {
      dialog.openErrorAlertDialog({
        msg: '17日未満(短時間労働者は11日未満)の月があります。',
        detail: '月額変更届の対象外のため、PDFやCSVに出力されません。'
      });
    }
  }

  get employee() {
    return this.yearly.employee_hash[this.update_stdincome_master.employee_id];
  }
  get branch() {
    return this.global.branch_all_hash[this.employee.branch_id];
  }
  

  get is_valid_update_stdincome() {
    return this.update_stdincome_master.is_valid == 1;
  }
  set is_valid_update_stdincome(val) {
    this.m.update({name: 'update_stdincome_master_valid', args: {update_stdincome_id: this.update_stdincome_master.update_stdincome_id, employee_id: this.update_stdincome_master.employee_id, is_valid: val}});
  }

  reflect() {
    this.m.update({name: 'update_stdincome_reflect', args: {update_stdincome_id: this.update_stdincome_master.update_stdincome_id, employee_id: this.update_stdincome_master.employee_id}});
  }
  unreflect() {
    this.m.update({name: 'update_stdincome_unreflect', args: {update_stdincome_id: this.update_stdincome_master.update_stdincome_id, employee_id: this.update_stdincome_master.employee_id}});
  }
}
