
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinUpdateStdincome from '@/mixins/mixinUpdateStdincome';
import dialog from "@/vuex/dialog";
import CommonPager from "@/components/common/Pager.vue";

@Component({
  components: {
    CommonPager,
  }
})
export default class Monthchanger extends Mixins(mixinUpdateStdincome) {
  get monthstr() {
    if (this.global && this.global.update_stdincome)
      return util.formatDate(util.date2obj(this.global.update_stdincome.started_yearmonth+'-01'), 'yyyy年mm月')+'〜'+util.formatDate(util.date2obj(this.global.update_stdincome.ended_yearmonth+'-01'), 'yyyy年mm月');
    else
      return '読み込み中';
  }

  prev_update_stdincome() {
    this.update_stdincome_id = this.prev_update_stdincome_id;
  }
  next_update_stdincome() {
    this.update_stdincome_id = this.next_update_stdincome_id;
  }

  get prev_update_stdincome_id() {
    return this.global?.prev_update_stdincome_id ?? null;
  }
  get next_update_stdincome_id() {
    return this.global?.next_update_stdincome_id ?? null;
  }
}
