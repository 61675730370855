
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinUpdateStdincome from '@/mixins/mixinUpdateStdincome';
import dialog from "@/vuex/dialog";
import manager from "@/vuex/update_stdincome/manager";
import CommonButton from "@/components/common/Button.vue";

@Component({
  components: {
    CommonButton,
  }
})
export default class Control extends Mixins(mixinUpdateStdincome) {
  create_update_stdincome() {
    this.m.openDialog({name: 'update_stdincome_create'});
  }
}
