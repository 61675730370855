
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinUpdateStdincome from '@/mixins/mixinUpdateStdincome';
import global from "@/vuex/update_stdincome/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/update_stdincome/manager";
import CommonProgress from "@/components/common/Progress.vue";
import UpdatestdincomeTableMain from "@/components/updatestdincome/table/Main.vue";

@Component({
  components: {
    CommonProgress,
    UpdatestdincomeTableMain,
  }
})
export default class Main extends Mixins(mixinUpdateStdincome) {
  
}
