
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinUpdateStdincome from '@/mixins/mixinUpdateStdincome';
import global from "@/vuex/update_stdincome/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/update_stdincome/manager";
import CommonInfobutton from "@/components/common/Infobutton.vue";
import UpdatestdincomeTableInputrow from "@/components/updatestdincome/table/Inputrow.vue";
import UpdatestdincomeTableRow from "@/components/updatestdincome/table/Row.vue";

@Component({
  components: {
    CommonInfobutton,
    UpdatestdincomeTableInputrow,
    UpdatestdincomeTableRow,
  }
})
export default class Table extends Mixins(mixinUpdateStdincome) {
  get update_stdincome_masters() {
    return this.datas.yearly.update_stdincome_masters;
  }
}
