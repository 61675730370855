
import { Component, Vue, Emit, Prop, Watch, Mixins } from 'vue-property-decorator';
import info_dialog from "@/vuex/info_dialog";
import info from "@/library/info";

@Component({
  components: {
  }
})
export default class Check extends Vue {
  @Prop() index?: string;
  @Prop() link?: string;

  @Emit()
  clicked() {
    if (this.index !== undefined) {
      info_dialog.openDialog(info[this.index]);
    } else if (this.link !== undefined) {
      window.open(this.link, '_blank');
    }
  }

  get is_slot() {
    return this.$slots.default !== undefined;
  }
}
