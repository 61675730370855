
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import utilMixins from '@/mixins';
import dialog from "@/vuex/dialog";
import mixinUpdateStdincome from '@/mixins/mixinUpdateStdincome';
import UpdatestdincomeNoupdatestdincome from "@/components/updatestdincome/Noupdatestdincome.vue";
import UpdatestdincomeMonthchanger from "@/components/updatestdincome/Monthchanger.vue";
import UpdatestdincomeMain from "@/components/updatestdincome/Main.vue";
import UpdatestdincomeDialogCreate from "@/components/updatestdincome/dialog/Create.vue";

@Component({
  components: {
    UpdatestdincomeNoupdatestdincome,
    UpdatestdincomeMonthchanger,
    UpdatestdincomeMain,
    UpdatestdincomeDialogCreate,
  }
})
export default class UpdateStdincome extends Mixins(mixinUpdateStdincome) {
}
