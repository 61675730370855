
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinUpdateStdincome from '@/mixins/mixinUpdateStdincome';
import dialog from "@/vuex/dialog";
import manager from "@/vuex/update_stdincome/manager";
import UpdatestdincomeTableBranchselector from "@/components/updatestdincome/table/Branchselector.vue";
import UpdatestdincomePagerHead from "@/components/updatestdincome/pager/Head.vue";
import CommonButton from "@/components/common/Button.vue";

@Component({
  components: {
    UpdatestdincomeTableBranchselector,
    UpdatestdincomePagerHead,
    CommonButton,
  }
})
export default class Control extends Mixins(mixinUpdateStdincome) {
  created() {
    this.m.setEditFlag({name: 'update_stdincome_masters', val: null});
  }
  erase() {
    this.m.delete({name: 'update_stdincome', args: {update_stdincome_id: this.update_stdincome_id}});
  }
  edit() {
    this.m.startEdit({name: 'update_stdincome_masters', val: true, object: this.yearly.update_stdincome_masters, hash_key: 'employee_id'});
  }
  cancel() {
    this.m.finishEdit({name: 'update_stdincome_masters'});
  }
  update() {
    this.m.update({name: 'update_stdincome_masters', args: {update_stdincome_id: this.update_stdincome_id, update_stdincome_masters: this.m.tmp_objects['update_stdincome_masters']}});
  }

  reflect() {
    this.m.update({name: 'update_stdincomes_reflect', args: {update_stdincome_id: this.update_stdincome_id, branch_id: this.branch_id}});
  }
  unreflect() {
    this.m.update({name: 'update_stdincomes_unreflect', args: {update_stdincome_id: this.update_stdincome_id, branch_id: this.branch_id}});
  }

  csv() {
    this.m.openDialog({name: 'update_stdincome_csv'});
  }
  pdf() {
    this.m.document({name: 'update_stdincome_pdf', args: {update_stdincome_id: this.update_stdincome_id, employee_ids: this.employee_ids}});
  }

  get employee_ids() {
    var update_stdincome_masters = util.deep_copy(this.yearly.update_stdincome_masters);
    update_stdincome_masters.sort((x, y) => util.sort_idx_nullfirst(this.yearly.employee_hash[x.employee_id].social_ins_id, this.yearly.employee_hash[y.employee_id].social_ins_id, 'asc'));
    return update_stdincome_masters.map(update_stdincome_master => update_stdincome_master.employee_id);
  }

  create_update_stdincome() {
    this.m.openDialog({name: 'update_stdincome_create'});
  }
  async summery_csv() {
    dialog.openProgressDialog({title: '作成中', msg: '作成に時間がかかることがあります。', detail: 'そのままお待ちください。'});
    await util.post_document('document/generate_social_ins_summery', {update_stdincome_id: this.update_stdincome_id, employee_ids: this.employee_ids}, '月額変更届電子申請総括票.pdf');
  }
}
