
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinUpdateStdincome from '@/mixins/mixinUpdateStdincome';
import global from "@/vuex/update_stdincome/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/update_stdincome/manager";
import CommonCheck from "@/components/common/Check.vue";
import CommonText from "@/components/common/Text.vue";
import CommonYearmonth from "@/components/common/Yearmonth.vue";
import CommonSelect from "@/components/common/Select.vue";

@Component({
  components: {
    CommonCheck,
    CommonText,
    CommonYearmonth,
    CommonSelect,
  }
})
export default class Row extends Mixins(mixinUpdateStdincome) {
  @Prop() update_stdincome_master;

  get yearmonthes() {
    var yearmonthes = [];
    var dateobj = util.date2obj(this.global.update_stdincome.started_yearmonth+'-01');
    yearmonthes.push(util.formatDate(dateobj, 'yyyy-mm'));
    yearmonthes.push(util.formatDate(util.next_month(dateobj), 'yyyy-mm'));
    yearmonthes.push(util.formatDate(util.next_month(dateobj), 'yyyy-mm'));
    return yearmonthes;
  }

  get employee() {
    return this.yearly.employee_hash[this.update_stdincome_master.employee_id];
  }
  get branch() {
    return this.global.branch_all_hash[this.employee.branch_id];
  }

  get tmp_update_stdincome_master() {
    return this.m.tmp_objects.update_stdincome_masters[this.update_stdincome_master.employee_id];
  }
  set tmp_update_stdincome_master(val) {
    this.m.setTmpObject({name: 'update_stdincome_masters', key: this.update_stdincome_master.employee_id, val: val});
  }

  input_retroactive(month) {
    if (month == 4) {
      this.tmp_update_stdincome_master['retroactive_2'] = null;
      this.tmp_update_stdincome_master['retroactive_3'] = null;
    } else if (month == 5) {
      this.tmp_update_stdincome_master['retroactive_1'] = null;
      this.tmp_update_stdincome_master['retroactive_3'] = null;
    } else if (month == 6) {
      this.tmp_update_stdincome_master['retroactive_1'] = null;
      this.tmp_update_stdincome_master['retroactive_2'] = null;
    }
  }

  get remove_over_seventy_month_keys() {
    if (this.tmp_update_stdincome_master['is_over_seventy'] && this.tmp_update_stdincome_master['over_seventy_month_2']) {
      return [this.tmp_update_stdincome_master['over_seventy_month_2']];
    } else {
      return [];
    }
  }
  get remove_over_seventy_month_2_keys() {
    if (this.tmp_update_stdincome_master['is_over_seventy'] && this.tmp_update_stdincome_master['over_seventy_month']) {
      return [this.tmp_update_stdincome_master['over_seventy_month']];
    } else {
      return [];
    }
  }












   

}
